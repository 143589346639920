import { createSlice } from '@reduxjs/toolkit';
import config from '../config';
import api from '../services/api';
import { pageObj } from '../utils/pageObj';

const initialState = {
    loading : false,
    hasErrors : false,
    res : null,    
    pageObj : [],
    pesan : ''
}

const fetchGeneralSlice = createSlice({
    name : 'fetch',
    initialState,
    reducers : {
        getData : (state) => { state.loading = true},
        getDataSuccess : (state, action) => {
            state.loading = false;
            state.hasErrors = false;
            state.res = action.payload.res;
            state.pageObj = action.payload.page;
        },
        getDataFailure : (state) => { state.loading = false; state.hasErrors = true},
    }
})

export const { getData, getDataSuccess, getDataFailure } = fetchGeneralSlice.actions;

export const fetchSelector = (state) => state;

export default fetchGeneralSlice.reducer;


export function fetchOffset(url, q='', limit=config.itemPerPage, offset=0){
    return async(dispatch) => {
        const data = {
            res : null,
            page : []
        }
        dispatch(getDataSuccess(data));
    
        dispatch(getData());
        try{            
            const res = await api.get(`${config.apiBaseUrl[process.env.NODE_ENV]}${url}?${q}`, {params : {limit, offset}}).then(res => res.data)
            if (res){
                let tmpPage = pageObj(res.count, limit, offset);                
                const data = {
                    res : res,
                    page : tmpPage
                }

                dispatch(getDataSuccess(data));
            }else{
                dispatch(getDataFailure());            
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}
