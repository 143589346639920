import api from './api';

// lokasi / gudang
export function tryHitungGaji(formData){
    return api.post(`/hrd/api/try-hitung-gaji`, formData).then(res => res.data);
}

export function simpanHitungGaji(formData){
    return api.post(`/hrd/api/save-hitung-gaji`, formData).then(res => res.data);
}

export function updateHitungGaji(formData, id){
    return api.put(`/hrd/api/update-hitung-gaji/${id}`, formData).then(res => res.data);
}

export function editHitungGaji(id){
    return api.get(`/hrd/api/hitung-gaji/${id}/`).then(res => res.data);
}

export function deleteHitungGaji(id){
    return api.delete(`/hrd/api/hitung-gaji/${id}/`).then(res => res.data);
}