import api from './api';

// akun
export function getAkun(keyword, limit, page){
    return api.get(`/akunting/api/master-akun?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

// default akun
export function getDefaultAkun(keyword, limit, page){
    return api.get(`/akunting/api/default-akun?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function postBulkDefaultAkun(form){
    return api.post(`/akunting/api/default-akun`, form).then(res => res.data);
}

// bank
export function getBank(keyword, limit, page){
    return api.get(`/akunting/api/bank?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function postBank(form){
    return api.post(`/akunting/api/bank`, form).then(res => res.data);
}

export function putBank(id, form){
    return api.put(`/akunting/api/bank/${id}`, form).then(res => res.data);
}


export function postAkun(formData){
    return api.post(`/akunting/api/master-akun`, formData).then(res => res.data);
}

export function putAkun(id, formData){
    return api.put(`/akunting/api/master-akun/${id}`, formData).then(res => res.data);
}

// method pembayaran
export function postMethodPembayaran(formData){
    return api.post(`/akunting/api/method-pembayaran`, formData).then(res => res.data);
}

export function putMethodPembayaran(id, formData){
    return api.put(`/akunting/api/method-pembayaran/${id}`, formData).then(res => res.data);
}

export function getMethodPembayaran(keyword, limit, page){
    return api.get(`/akunting/api/method-pembayaran?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function deleteMethodPembayaran(id){
    return api.delete(`/akunting/api/method-pembayaran/${id}`).then(res => res.data);
}

// jurnal
export function getJurnal(keyword, limit, page){
    return api.get(`/akunting/api/jurnal?${keyword}`, {params:{limit, page}}).then(res => res.data);
}


// paid state
export function getPaidState(keyword){
    return api.get(`/akunting/api/kwitansi/get-paid-state/?${keyword}`).then(res => res.data);
}

// paid priod
export function getPaidPeriod(){
    return api.get(`/akunting/api/kwitansi/get-paid-period`).then(res => res.data);
}

