import { combineReducers } from 'redux'

import postsReducer from './perusahaan'
import provinsiReducer from './provinsi'
import kabupatenReducer from './kabupaten';
import lokasiReducer from './lokasi';
import kategoriReducer from './kategoriproduk';
import produkReducer from './produk';
import satuanReducer from './satuan';
import kontakReducer from './kontak';
import billingReducer from './billing';
import kwitansiReducer from './kwitansi';
import akunReducer from './accounting';
import bankReducer from './bank';
import methodReducer from './methodpembayaran';
import jurnalReducer from './jurnal';
import fetchReducer from './fetchSlice';
import productionReducer from './productionMode';
import roleReducer from './roles'; 
import cabangReducer from './cabangSlice'; 
import rekananReducer from './rekananSlice'; 
// import postReducer from './post'
// import commentsReducer from './comments'

const rootReducer = combineReducers({
    posts: postsReducer,
    provinsis : provinsiReducer,
    kabupatens : kabupatenReducer,      
    lokasis : lokasiReducer,    
    kategories : kategoriReducer,
    produks : produkReducer,
    satuans : satuanReducer,
    kontaks : kontakReducer,
    billings : billingReducer,
    kwitansis : kwitansiReducer,
    banks : bankReducer,
    methodpembayarans : methodReducer,
    jurnals : jurnalReducer,
    fetchGeneral : fetchReducer,
    productionObj : productionReducer,
    akuns : akunReducer,
    roles : roleReducer,    
    cabangs : cabangReducer,    
    rekanans : rekananReducer,    
})

export default rootReducer
