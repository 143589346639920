import axios from 'axios';
import config from '../config';



const headers = { 'Accept' : 'application/json', 'Content-Type' : 'application/json' }



const api = axios.create({
    baseURL : config.apiBaseUrl[process.env.NODE_ENV],
    headers: headers,    
    timeout : 50000
});


api.interceptors.request.use(
    function(configure) {
        const token = localStorage.getItem(config.localStorageKey.ACCESS_TOKEN); 
        // const myCookies = cookies.get('gifihome');        
        if (token) {                        
            configure.headers["Authorization"] = 'Token ' + token;
        }
        return configure;
    },
    function(error) {
        return Promise.reject(error);        
    }
);



export default api;