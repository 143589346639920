import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { Alert, Col, Row, Table } from 'reactstrap';
// import * as masterService from '../../services/masterService';
import * as hrdService from '../../services/hrdService';
import config from '../../config';
import LoaderComponent from '../../containers/Components/LoaderComponent';

const CetakRekapGaji = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const hitungGajiId = sessionStorage.getItem('hitungGajiId') || 0 ;
    // const [listTunjangan, setListTunjangan] = useState([]);
    const [detailGaji, setDetailGaji] = useState([]);    
    const [alertValue, setAlertValue] = useState({
        show: false,
        text : '',
        color : 'danger'
    })

    const fetchDetail = useCallback(async(id=0) => {
        setIsLoading(true);
        try{
            const res = await hrdService.editHitungGaji(id)
            setIsLoading(false);
            let tmp = []
            
            res.results.hitung_gaji_pegawai.map(post => {
                let data = {...post.detail_gaji}
                data['nama'] = post.nama_pegawai;
                tmp.push(data);
                return true;
            })
            setDetailGaji(tmp)
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show : true, text : 'Unable to fetch Data Pegawai', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show : false}));
            }, config.timeOutValue)
        }
    },[])

    useEffect(() => {
        if (hitungGajiId !== 0){
            fetchDetail(hitungGajiId);
        }
    },[hitungGajiId, fetchDetail])

    const numberToString = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    return(
        <Fragment>
            { isLoading && <LoaderComponent/> }
            <Alert isOpen={alertValue.show} toggle={() => setAlertValue(alertValue => ({...alertValue, show : false}))} color={alertValue.color}>
                {alertValue.text}
            </Alert>
            <div className="rekap-gaji-container">
                <Row>
                    <Col xs={12}>
                        <div className="text-center">Cetak Rekap Gaji Periode</div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Table className='table table-bordered table-small table-center'>
                            <thead>
                                <tr>
                                    <th rowSpan='2'>#</th>
                                    <th rowSpan='2'>Nama</th>
                                    <th rowSpan='2'>Jabatan</th>
                                    <th colSpan='7'>Gaji dan Tunjangan</th>
                                    <th colSpan='4'>Potongan dan Pinjaman</th>
                                    <th rowSpan='2'>Total Gaji</th>
                                </tr>
                                <tr>
                                    <th>Gaji Pokok</th>
                                    <th>Tunjangan Jabatan</th>
                                    <th>Uang Makan</th>
                                    <th>Transport</th>
                                    <th>Tunjangan Perkawinan</th>
                                    <th>Tunjangan Anak</th>
                                    <th>Lembur</th>
                                    <th>Jamsostek</th>
                                    <th>Koperasi</th>
                                    <th>Zakat</th>
                                    <th>Kasbon</th>
                                </tr>                                        
                            </thead>
                            <tbody>
                                { detailGaji.map((post, index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{index+1}</td>
                                            <td>{post.nama}</td>
                                            <td>{post.jabatan}</td>
                                            <td className='text-right'>{numberToString(post.gaji_pokok)}</td>
                                            <td className='text-right'>{numberToString(post.tunjangan_jabatan)}</td>
                                            <td className='text-right'>{numberToString(post.uang_makan)}</td>
                                            <td className='text-right'>{numberToString(post.uang_transport)}</td>
                                            <td className='text-right'>{numberToString(post.tunjangan_perkawinan)}</td>
                                            <td className='text-right'>{numberToString(post.tunjangan_anak)}</td>
                                            <td className='text-right'>{numberToString(post.lembur)}</td>
                                            <td className='text-right'>{numberToString(post.bpjs_naker)}</td>
                                            <td className='text-right'>{numberToString(post.koperasi)}</td>
                                            <td className='text-right'>{numberToString(post.zakat)}</td>
                                            <td className='text-right'>{numberToString(post.kasbon)}</td>
                                            <td className='text-right'>{numberToString(post.total_gaji)}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        </Fragment>
    )
}
export default CetakRekapGaji