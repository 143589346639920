import api from './api';

// lokasi / gudang
export function getLokasi(keyword, limit, page){
    return api.get(`/produk/api/lokasi?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function deleteLokasi(id){
    return api.delete(`/produk/api/lokasi/${id}`).then(res => res.data)
}

export function postLokasi(formData){
    return api.post(`/produk/api/lokasi`, formData).then(res => res.data);
}

export function putLokasi(id, formData){
    return api.put(`/produk/api/lokasi/${id}`, formData).then(res => res.data);
}

export function tryImportOdp(formData){
    return api.post(`/master/api/odp/import`, formData).then(res => res.data);
}

export function saveImportOdp(formData){
    return api.post(`/master/api/odp/save-import`, formData).then(res => res.data);
}

export function postMobileApps(formData){
    return api.post(`/master/api/mobile-apps`, formData).then(res => res.data);
}

export function putMobileApps(formData, id){
    return api.put(`/master/api/mobile-apps/${id}`, formData).then(res => res.data);
}

export function getMobileApps(keyword, limit, page){
    return api.get(`/master/api/get-mobile-apps?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function deleteMobileApps(id){
    return api.delete(`/master/api/mobile-apps/${id}`).then(res => res.data);
}


// lokasi gaji
export function getGaji(keyword, limit, page){
    return api.get(`/master/api/gaji?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function deleteGaji(id){
    return api.delete(`/master/api/gaji/${id}`).then(res => res.data)
}

export function postGaji(formData){
    return api.post(`/master/api/gaji`, formData).then(res => res.data);
}

export function putGaji(formData, id){
    return api.put(`/master/api/gaji/${id}`, formData).then(res => res.data);
}

export function getGajiOpt(){
    return api.get(`/master/api/get-gaji-opt`).then(res => res.data);
}

// potongan
export function getPotonganOpt(){
    return api.get(`/master/api/get-potongan-opt`).then(res => res.data);
}

export function postPotongan(formData){
    return api.post(`/master/api/potongan`, formData).then(res => res.data);
}

export function putPotongan(formData, id){
    return api.put(`/master/api/potongan/${id}`, formData).then(res => res.data);
}

export function getPotongan(keyword, limit, page){
    return api.get(`/master/api/potongan?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function deletePotongan(id){
    return api.delete(`/master/api/potongan/${id}`).then(res => res.data)
}

export function deletePotonganDetail(id){
    return api.delete(`/master/api/potongan-detail/${id}`).then(res => res.data)
}