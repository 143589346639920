import api from './api';


//  produk
export function postProduk(formData){
    return api.post(`/produk/api/produk`, formData).then(res => res.data);
}

export function putProduk(id, formData){
    return api.put(`/produk/api/produk/${id}`, formData).then(res => res.data);
}

export function getProduk(keyword, limit, page){
    return api.get(`/produk/api/produk?${keyword}`, {params:{limit, page}}).then(res => res.data)
}

export function deleteProduk(id){
    return api.delete(`/produk/api/produk/${id}`).then(res => res.data)
}

export function getOpenBalanceData(keyword, limit, page){
    return api.get(`/produk/api/produk/log?${keyword}`, {params: {limit, page}}).then(res => res.data);
}

//  layanan internet
export function postLayananInternet(formData){
    return api.post(`/produk/api/layanan-internet`, formData).then(res => res.data);
}

export function putLayananInternet(id, formData){
    return api.put(`/produk/api/layanan-internet/${id}`, formData).then(res => res.data);
}



//  produk option
export function getProdukOption(formData){
    return api.get(`/produk/api/get-produk-option`).then(res => res.data);
}


export function getSaldoProdukLog(keyword, limit, page){
    return api.get(`/produk/api/produk/log?tipe=all&trx_type=get_by_saldo&${keyword}`, {params : {limit, page}}).then(res => res.data);
}

export function getProdukLog(keyword, limit, page){
    return api.get(`/produk/api/produk/log?tipe=history&${keyword}`, {params : {limit, page}}).then(res => res.data);
}

export function tryImportOnu(formData){
    return api.post(`/produk/api/onu/import`, formData).then(res => res.data);
}

export function saveImportOnu(formData){
    return api.post(`/produk/api/onu/save-import`, formData).then(res => res.data);
}