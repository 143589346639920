import api from './api';


// suplier
export function getSuplier(keyword, limit, page){
    return api.get(`/kontak/api/kontak?tipe=suplier&${keyword}`, {params:{limit, page}}).then(res => res.data);
}
export function postSuplier(formData){
    return api.post(`/kontak/api/kontak`, formData).then(res => res.data);
}

export function putSuplier(formData, id){
    return api.put(`/kontak/api/suplier/${id}`, formData).then(res => res.data);
}


export function getKontak(keyword, limit, page){
    return api.get(`/kontak/api/kontak?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function postKontak(formData){
    return api.post(`/kontak/api/kontak`, formData).then(res => res.data);
}

export function putKontak(id, formData){
    return api.put(`/kontak/api/kontak/${id}`, formData).then(res => res.data);
}

export function deleteKontak(id){
    return api.delete(`/kontak/api/${id}`).then(res => res.data);
}

export function getTipeKontak(){
    return api.get(`/kontak/api/get-tipe-kontak`).then(res => res.data);
}

// salesman
export function postSalesman(formData){
    return api.post(`/kontak/api/salesman`, formData).then(res => res.data);
}

export function putSalesman(formData, id){
    return api.put(`/kontak/api/salesman/${id}`, formData).then(res => res.data);
}

export function getSalesman(keyword, limit, page){
    return api.get(`/kontak/api/salesman?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function tryImportSalesman(formData){
    return api.post(`/kontak/api/salesman/import`, formData).then(res => res.data);
}

export function saveImportSalesman(formData){
    return api.post(`/kontak/api/salesman/save-import`, formData).then(res => res.data);
}

export function deleteSalesman(id){
    return api.delete(`/kontak/api/salesman/${id}`).then(res => res.data);
}

export function tryImportTeknis(formData){
    return api.post(`/kontak/api/teknis/import`, formData).then(res => res.data);
}

export function saveImportTeknis(formData){
    return api.post(`/kontak/api/teknis/save-import`, formData).then(res => res.data);
}

// pegawai
export function postPegawai(formData){
    return api.post(`/kontak/api/pegawai`, formData).then(res => res.data);
}

export function putPegawai(formData, id){
    return api.put(`/kontak/api/pegawai/${id}`, formData).then(res => res.data);
}

export function getPegawai(keyword, limit, page){
    return api.get(`/kontak/api/pegawai?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function getPegawaiOpt(){
    return api.get(`/kontak/api/get-pegawai-opt`).then(res => res.data);
}