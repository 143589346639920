import { createSlice } from '@reduxjs/toolkit';
// import { createStore } from 'redux';

const initialState = {
    loadingProduction : false,
    isDevelopment : false,    
    text : '',
}

const productionModeStore = createSlice({
    name : 'productionStore',
    initialState,
    reducers : {
        setData : (state, action) => {                       
            state.isDevelopment = action.payload.isDevelopment;
            state.text = action.payload.text;
            state.loadingProduction = false;
        },
    }
})

export const { setData } = productionModeStore.actions;

export const productionSelector = (state) => state;

export default productionModeStore.reducer;