import { createSlice } from '@reduxjs/toolkit';
import * as produkService from '../services/produkService';
import config from '../config';

const initialState = {
    loading : false,
    hasErrors : false,
    produks : [],
    produk : {},
    list_page : [],
    saldos : [],
    pesan : ''
}

const produkStoreSlice = createSlice({
    name : 'produkStore',
    initialState,
    reducers : {
        getData : (state) => { state.loading = true},
        getDataSuccess : (state, action) => {
            state.loading = false;
            state.hasErrors = false;
            state.produks = action.payload.data;
            state.list_page = action.payload.list_page;
        },
        getDataFailure : (state) => { state.loading = false; state.hasErrors = true},
        setDetail : (state, action) => {
            state.loading = false;
            state.hasErrros = false;
            state.produk = action.payload;
        },
        setSaldos : (state, action) => {
            state.loading = false;
            state.hasErrors = false;
            state.saldos = action.payload.data;
            state.list_page = action.payload.list_page;
        },
        deleteData : (state, action) => {            
            state.loading = false;
            state.hasErrors = false;
            state.produks = state.produks.filter(item => item.id !== action.payload);
        },
        setPesan : (state, action) => {
            state.pesan = action.payload;
        }
    }
})

export const { getData, getDataSuccess, getDataFailure, setDetail, deleteData, setPesan, setSaldos } = produkStoreSlice.actions;

export const produkSelector = (state) => state.produks;

export default produkStoreSlice.reducer;


export function fetchProduk(q='', limit=config.itemPerPage, page=1){
    return async(dispatch) => {
        dispatch(getData());
        try{
            const response = await produkService.getProduk(q, limit, page);
            const data = await response;            
            if(data.sukses === 'yes'){
                dispatch(getDataSuccess(data))
            }else{
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}

export function fetchSaldo(q='', limit=config.itemPerPage, page=1){
    return async(dispatch) => {
        dispatch(getData());
        try{
            const response = await produkService.getOpenBalanceData(q, limit, page);
            const data = await response;            
            if(data.sukses === 'yes'){
                dispatch(setSaldos(data))
            }else{
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}


export function fetchDetail(id){
    return async(dispatch) => {
        dispatch(getData());
        try{
            const response = await produkService.getProduk(`id=${id}`, 1, 1);
            const data = await response;            
            if(data.sukses === 'yes'){
                dispatch(setDetail(data.data))
            }else{
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}

export function deleteProduk(id){
    return async(dispatch) => {
        dispatch(getData());
        try{
            const response = await produkService.deleteProduk(id);
            const data = await response;
            if (data.sukses === 'yes'){                
                dispatch(deleteData(id));
                dispatch(setPesan(data.pesan))
            }else{
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}