import React from 'react';
import '../../style/ads.css'
import StepSatu from './StepSatu';

const LaporanIklan = (props) => {
    return (
        <div className="iklan-container">
            <StepSatu/>
        </div>
    )
}
export default LaporanIklan