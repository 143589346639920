import { createSlice } from '@reduxjs/toolkit';
import * as billingService from '../services/billingService';
import config from '../config';

const initialState = {
    loading : false,
    hasErrors : false,
    billings : [],
    billing : {},
    list_page : [],
    resume_billing : [],
    pesan : ''
}

const billingStoreSlice = createSlice({
    name : 'billingStore',
    initialState,
    reducers : {
        getData : (state) => { state.loading = true},
        getDataSuccess : (state, action) => {
            state.loading = false;
            state.hasErrors = false;
            state.billings = action.payload.data;
            state.list_page = action.payload.list_page;
        },
        setResumeBilling : (state, action) => {
            state.resume_billing = action.payload
        },
        getDataFailure : (state) => { state.loading = false; state.hasErrors = true},
        setDetail : (state, action) => {
            state.loading = false;
            state.hasErrros = false;
            state.billing = action.payload;
        },
        deleteData : (state, action) => {            
            state.loading = false;
            state.hasErrors = false;
            state.billings = state.billings.filter(item => item.id !== action.payload);
        },
        setPesan : (state, action) => {
            state.pesan = action.payload;
        }
    }
})

export const { getData, getDataSuccess, getDataFailure, setDetail, deleteData, setPesan, setResumeBilling } = billingStoreSlice.actions;

export const billingSelector = (state) => state.billings;

export default billingStoreSlice.reducer;


export function fetchBilling(q='', limit=config.itemPerPage, page=1){
    return async(dispatch) => {
        dispatch(getData());
        try{
            const response = await billingService.getBilling(q, limit, page);
            const data = await response;            
            if(data.sukses === 'yes'){
                dispatch(getDataSuccess(data))
                dispatch(setResumeBilling(data.resume))
            }else{
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}

export function fetchBillingDetail(id){
    return async(dispatch) => {
        dispatch(getData());
        try{
            const response = await billingService.getBilling(`id=${id}`, 1, 1);
            const data = await response;            
            if(data.sukses === 'yes'){
                dispatch(setDetail(data.data))
            }else{
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}

export function deleteBilling(id){
    return async(dispatch) => {
        dispatch(getData());
        try{
            const response = await billingService.deleteBilling(id);
            const data = await response;
            if (data.sukses === 'yes'){
                console.log('sukses')
                dispatch(deleteData(id));
                dispatch(setPesan(data.pesan))
            }else{
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}