import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import CetakInvoicePage from './views/Billing/CetakInvoice/CetakInvoicePage';
import CetakRekapGaji from './views/HRD/CetakRekapGaji';
import LaporanIklan from './views/Laporan/LaporanIklan';


const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;


// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
// const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const LoginPage = React.lazy(() => import('./views/Authentication/LoginPage'))

class App extends Component {

  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              {/* <Route exact path="/login" name="Login Page" render={props => <LoginPage {...props}/>} /> */}
              <Route exact path="/login" name="Login Page" render={props => <LoginPage {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/adszilla" name="AdsZilla" render={props => <LaporanIklan {...props}/>} />
              <Route exact path="/hrd/cetak-rekap-gaji" name="Rekap Gaji" render={props => <CetakRekapGaji {...props} />} />
              <Route exact path="/tagihan/cetak-invoice" name="Cetak Invoice" render={props => <CetakInvoicePage {...props} />} />
              <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
