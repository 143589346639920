import api from './api';

// meeting room
export function getKwitansi(keyword, limit, page){
    return api.get(`/akunting/api/kwitansi/?${keyword}`, {params:{limit, page}}).then(res => res.data)
}

export function postPembayaran(id, form){
    return api.post(`/akunting/api/pembayaran/${id}`, form).then(res => res.data)
}

export function getTokenPayment(){
    return api.get(`/akunting/api/get-payment-token`).then(res => res.data)
}