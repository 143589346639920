import { createSlice } from '@reduxjs/toolkit';
import config from '../config';
import api from '../services/api';



const initialState = {
    loading : false,
    hasErrors : false,
    cabangs : [],
    role : {},
    list_page : [],
    is_superuser : false,
    pesan : ''
}

const cabangStoreSlice = createSlice({
    name : 'cabangStore',
    initialState,
    reducers : {
        getData : (state) => { state.loading = true},
        getDataSuccess : (state, action) => {
            state.loading = false;
            state.hasErrors = false;
            state.cabangs = action.payload;            
        },
        getDataFailure : (state) => { state.loading = false; state.hasErrors = true},
        setDetail : (state, action) => {
            state.loading = false;
            state.hasErrros = false;
            state.role = action.payload;
        },
        deleteData : (state, action) => {            
            state.loading = false;
            state.hasErrors = false;
            state.roles = state.roles.filter(item => item.id !== action.payload);
        },
        setPesan : (state, action) => {
            state.pesan = action.payload;
        },
        setCabangs : (state, action) => {
            state.cabangs = action.payload
        }
    }
})

export const { getData, getDataSuccess, getDataFailure, setDetail, deleteData, setPesan, setCabangs } = cabangStoreSlice.actions;

export const roleSelector = (state) => state.roles;

export default cabangStoreSlice.reducer;

export function fetchCabangs(){
    return async(dispatch) => {
        dispatch(getData());
        try{
            let params = {
                by_user : 'yes'
            }
            const res = await api.get(`${config.endPoint.cabangv1}`, {params: params}).then(res => res.data);                        
            if(res){
                dispatch(getDataSuccess(res.results));
            }else{
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}