import React, { Fragment, useEffect, useState } from 'react';


const StepSatu = (props) => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    
    const isMobile = width <= 768;

    console.log('isMobile is >>>', isMobile)
    return(
        <Fragment>
            <div className={isMobile ? "step-satu-container view-mobile" : "step-satu-container"}>
                <div className="box-ul-ads box-ads-shadow box-ul-ads-fitur">
                    <div className="box-ads-title box-ads-title-blue">FITUR</div>
                    <div className="box-ads-oval"></div>
                    <ul className='ul-ads-description'>                
                        <li>
                            <div className="bullet"></div>
                            <div className="li-content">
                                <div className='li-title'>Custom Access Point</div>
                                <div className="li-description"></div>
                            </div>
                        </li>
                        <li>
                            <div className="bullet"></div>
                            <div className="li-content">
                                <div className='li-title'>Dashboard Pengiklan</div>
                                <div className="li-description"></div>
                            </div>
                        </li>
                        <li>
                            <div className="bullet"></div>
                            <div className="li-content">
                                <div className='li-title'>Topup Deposite Pengkilan</div>
                                <div className="li-description"></div>
                            </div>
                        </li>
                        <li>
                            <div className="bullet"></div>
                            <div className="li-content">
                                <div className='li-title'>Create Campaign</div>
                                <div className="li-description">Campaign sesuai dengan target audiens, Minat, pekerjaan, gender, usia, wilayah</div>
                            </div>
                        </li>
                        <li>
                            <div className="bullet"></div>
                            <div className="li-content">
                                <div className='li-title'>Kategori Iklan</div>
                                <div className="li-description"></div>
                            </div>
                        </li>
                        <li>
                            <div className="bullet"></div>
                            <div className="li-content">
                                <div className='li-title'>Appoval Iklan sebelum Tayang</div>
                                <div className="li-description"></div>
                            </div>
                        </li>
                        <li>
                            <div className="bullet"></div>
                            <div className="li-content">
                                <div className='li-title'>Dashboard Per Wilayah AP</div>
                                <div className="li-description"></div>
                            </div>
                        </li>
                        <li>
                            <div className="bullet"></div>
                            <div className="li-content">
                                <div className='li-title'>Laporan Visitor Baru</div>
                                <div className="li-description"></div>
                            </div>
                        </li>
                        <li>
                            <div className="bullet"></div>
                            <div className="li-content">
                                <div className='li-title'>Laporan Returning Visitor</div>
                                <div className="li-description"></div>
                            </div>
                        </li>
                        <li>
                            <div className="bullet"></div>
                            <div className="li-content">
                                <div className='li-title'>Laporan Visitor by Gender</div>
                                <div className="li-description"></div>
                            </div>
                        </li>
                        <li>
                            <div className="bullet"></div>
                            <div className="li-content">
                                <div className='li-title'>Laporan Visitor by Usia</div>
                                <div className="li-description"></div>
                            </div>
                        </li>
                        <li>
                            <div className="bullet"></div>
                            <div className="li-content">
                                <div className='li-title'>Laporan Visitor by Pekerjaan</div>
                                <div className="li-description"></div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="box-ul-ads box-ads-shadow box-ul-ads-higo">
                    <div className="box-ads-title box-ads-title-blue">HIGO</div>
                    <div className="box-ads-oval"></div>
                    <ul className='ul-ads-description'>
                        <li>
                            <div className="li-content">
                                <i className='fa fa-check-circle-o'></i>
                            </div>
                        </li>
                        <li>
                            <div className="li-content">
                                <i className='fa fa-check-circle-o'></i>
                            </div>
                        </li>
                        <li>
                            <div className="li-content">
                                <i className='fa fa-check-circle-o'></i>
                            </div>
                        </li>
                        <li>
                            <div className="li-content space-more">
                                <i className='fa fa-check-circle-o'></i>
                            </div>
                        </li>
                        <li>
                            <div className="li-content">
                                <i className='fa fa-check-circle-o'></i>
                            </div>
                        </li>
                        <li>
                            <div className="li-content space-more-1">
                                <i className='fa fa-check-circle-o'></i>
                            </div>
                        </li>
                        <li>
                            <div className="li-content">
                                <i className='fa fa-check-circle-o'></i>
                            </div>
                        </li>
                        <li>
                            <div className="li-content">
                                <i className='fa fa-check-circle-o'></i>
                            </div>
                        </li>
                        <li>
                            <div className="li-content">
                                <i className='fa fa-check-circle-o'></i>
                            </div>
                        </li>
                        <li>
                            <div className="li-content">
                                <i className='fa fa-check-circle-o'></i>
                            </div>
                        </li>
                        <li>
                            <div className="li-content">
                                <i className='fa fa-check-circle-o'></i>
                            </div>
                        </li>
                        <li>
                            <div className="li-content">
                                <i className='fa fa-check-circle-o'></i>
                            </div>
                        </li>

                    </ul>
                </div>
                <div className="box-ul-ads box-ads-shadow box-ul-ads-higo">
                    <div className="box-ads-title box-ads-title-blue">ADSZILLA</div>
                    <div className="box-ads-oval"></div>
                    <ul className='ul-ads-description'>
                        <li>
                            <div className="li-content">
                                <i className='fa fa-check-circle-o'></i>
                            </div>
                        </li>
                        <li>
                            <div className="li-content">
                                <i className='fa fa-check-circle-o'></i>
                            </div>
                        </li>
                        <li>
                            <div className="li-content">
                                <i className='fa fa-check-circle-o'></i>
                            </div>
                        </li>
                        <li>
                            <div className="li-content space-more">
                                <i className='fa fa-check-circle-o'></i>
                            </div>
                        </li>
                        <li>
                            <div className="li-content">
                                <i className='fa fa-check-circle-o'></i>
                            </div>
                        </li>
                        <li>
                            <div className="li-content space-more-1">
                                <i className='fa fa-check-circle-o'></i>
                            </div>
                        </li>
                        <li>
                            <div className="li-content">
                                <i className='fa fa-check-circle-o'></i>
                            </div>
                        </li>
                        <li>
                            <div className="li-content">
                                <i className='fa fa-check-circle-o'></i>
                            </div>
                        </li>
                        <li>
                            <div className="li-content">
                                <i className='fa fa-check-circle-o'></i>
                            </div>
                        </li>
                        <li>
                            <div className="li-content">
                                <i className='fa fa-check-circle-o'></i>
                            </div>
                        </li>
                        <li>
                            <div className="li-content">
                                <i className='fa fa-check-circle-o'></i>
                            </div>
                        </li>
                        <li>
                            <div className="li-content">
                                <i className='fa fa-check-circle-o'></i>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
            { isMobile &&
                <div className="mobile-rotate-icon-container">
                    <img src={require('../../assets/img/rotate-mobile.png')} alt='icon'/>
                </div>
            }
        </Fragment>
        // <Table className='table-center table-ads-satu'>
        //     <thead>
        //         <tr>
        //             <th>Fitur</th>
        //             <th className='text-center'>Higo</th>
        //             <th className='text-center'>AdsZilla</th>
        //         </tr>
        //     </thead>
        //     <tbody>
        //         <tr>
        //             <td>Custom Access Point</td>
        //             <td style={{ color: '#4dbd74' }} className='text-center'><i className='fa fa-check'></i></td>
        //             <td style={{ color: '#4dbd74' }} className='text-center'><i className='fa fa-check'></i></td>
        //         </tr>
        //         <tr>
        //             <td>Dashboard Pengiklan</td>
        //             <td style={{ color: '#4dbd74' }} className='text-center'><i className='fa fa-check'></i></td>
        //             <td style={{ color: '#4dbd74' }} className='text-center'><i className='fa fa-check'></i></td>
        //         </tr>
        //         <tr>
        //             <td>Topup Deposite Pengkilan</td>
        //             <td style={{ color: '#4dbd74' }} className='text-center'><i className='fa fa-check'></i></td>
        //             <td style={{ color: '#4dbd74' }} className='text-center'><i className='fa fa-check'></i></td>
        //         </tr>
        //         <tr>
        //             <td>Membuat Campaign sesuai dengan target audiens, Minat, pekerjaan, gender, usia, wilayah</td>
        //             <td style={{ color: '#4dbd74' }} className='text-center'><i className='fa fa-check'></i></td>
        //             <td style={{ color: '#4dbd74' }} className='text-center'><i className='fa fa-check'></i></td>
        //         </tr>
        //         <tr>
        //             <td>Kategori Iklan</td>
        //             <td style={{ color: '#4dbd74' }} className='text-center'><i className='fa fa-check'></i></td>
        //             <td style={{ color: '#4dbd74' }} className='text-center'><i className='fa fa-check'></i></td>
        //         </tr>
        //         <tr>
        //             <td>Appoval Iklan sebelum Tayang</td>
        //             <td style={{ color: '#4dbd74' }} className='text-center'><i className='fa fa-check'></i></td>
        //             <td style={{ color: '#4dbd74' }} className='text-center'><i className='fa fa-check'></i></td>
        //         </tr>
        //         <tr>
        //             <td>Dashboard Per Wilayah AP</td>
        //             <td style={{ color: '#4dbd74' }} className='text-center'><i className='fa fa-check'></i></td>
        //             <td style={{ color: '#4dbd74' }} className='text-center'><i className='fa fa-check'></i></td>
        //         </tr>
        //         <tr>
        //             <td>Laporan Visitor Baru</td>
        //             <td style={{ color: '#4dbd74' }} className='text-center'><i className='fa fa-check'></i></td>
        //             <td style={{ color: '#4dbd74' }} className='text-center'><i className='fa fa-check'></i></td>
        //         </tr>
        //         <tr>
        //             <td>Laporan Returning Visitor</td>
        //             <td style={{ color: '#4dbd74' }} className='text-center'><i className='fa fa-check'></i></td>
        //             <td style={{ color: '#4dbd74' }} className='text-center'><i className='fa fa-check'></i></td>
        //         </tr>
        //         <tr>
        //             <td>Laporan Visitor by Gender</td>
        //             <td style={{ color: '#4dbd74' }} className='text-center'><i className='fa fa-check'></i></td>
        //             <td style={{ color: '#4dbd74' }} className='text-center'><i className='fa fa-check'></i></td>
        //         </tr>
        //         <tr>
        //             <td>Laporan Visitor by Usia</td>
        //             <td style={{ color: '#4dbd74' }} className='text-center'><i className='fa fa-check'></i></td>
        //             <td style={{ color: '#4dbd74' }} className='text-center'><i className='fa fa-check'></i></td>
        //         </tr>
        //         <tr>
        //             <td>Laporan Visitor by Pekerjaan</td>
        //             <td style={{ color: '#4dbd74' }} className='text-center'><i className='fa fa-check'></i></td>
        //             <td style={{ color: '#4dbd74' }} className='text-center'><i className='fa fa-check'></i></td>
        //         </tr>
        //     </tbody>
        // </Table>
    )
}
export default StepSatu