import api from './api';


export function getProvinsiKodepos(){
    return api.get(`/master/api/kodepos/provinsi`).then(res => res.data);
}

export function getKabupatenKodepos(provinsi){
    return api.get(`/master/api/kodepos/kabupaten?provinsi=${provinsi}`).then(res => res.data);
}

export function getKecamatanKodepos(kabupaten){
    return api.get(`/master/api/kodepos/kecamatan?kabupaten=${kabupaten}`).then(res => res.data);
}

export function getKelurahanKodepos(kecamatan){
    return api.get(`/master/api/kodepos/kelurahan?kecamatan=${kecamatan}`).then(res => res.data);
}

export function getWilayah(keyword, limit, page){
    return api.get(`/master/api/wilayah?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function postWilayah(formData){
    return api.post(`/master/api/wilayah`, formData).then(res => res.data);
}

export function putWilayah(id, formData){
    return api.put(`/master/api/wilayah/${id}`, formData).then(res => res.data);
}

export function deleteWilayah(id){
    return api.delete(`/master/api/wilayah/${id}`).then(res => res.data);
}

export function getProvinsi(keyword, limit, page){
    return api.get(`/master/api/provinsi?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function putProvinsi(id, form){
    return api.put(`/master/api/provinsi/${id}`, form).then(res => res.data);
}

export function postProvinsi(form){
    return api.post(`/master/api/provinsi`, form).then(res => res.data);
}


export function getKabupaten(keyword, limit, page){
    return api.get(`/master/api/kabupaten?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function getKecamatan(keyword, limit, page){
    return api.get(`/master/api/kecamatan?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function getDesa(keyword, limit, page){
    return api.get(`/master/api/desa?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function updateKabupaten(formData){
    return api.push(`/master/api`, formData).then(res => res);
}
