import { createSlice } from '@reduxjs/toolkit';
import * as tagihanService from '../services/tagihanService';
import config from '../config';

const initialState = {
    loading : false,
    hasErrors : false,
    kwitansis : [],
    kwitansi : {},
    list_page : [],
    pesan : ''
}

const kwitansiStoreSlice = createSlice({
    name : 'kwitansiStore',
    initialState,
    reducers : {
        getData : (state) => { state.loading = true},
        getDataSuccess : (state, action) => {
            state.loading = false;
            state.hasErrors = false;
            state.kwitansis = action.payload.data;
            state.list_page = action.payload.list_page;
        },
        getDataFailure : (state) => { state.loading = false; state.hasErrors = true},
        setDetail : (state, action) => {
            state.loading = false;
            state.hasErrros = false;
            state.kwitansi = action.payload;
        },
        deleteData : (state, action) => {            
            state.loading = false;
            state.hasErrors = false;
            state.kwitansis = state.kwitansis.filter(item => item.id !== action.payload);
        },
        setPesan : (state, action) => {
            state.pesan = action.payload;
        }
    }
})

export const { getData, getDataSuccess, getDataFailure, setDetail, deleteData, setPesan } = kwitansiStoreSlice.actions;

export const kwitansiSelector = (state) => state.kwitansis;

export default kwitansiStoreSlice.reducer;


export function fetchKwitansi(q='', limit=config.itemPerPage, page=1){
    return async(dispatch) => {
        dispatch(getData());
        try{
            const response = await tagihanService.getKwitansi(q, limit, page);
            const data = await response;            
            if(data.sukses === 'yes'){
                dispatch(getDataSuccess(data))
            }else{
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}


export function fetchDetail(id){
    return async(dispatch) => {
        dispatch(getData());
        try{
            const response = await tagihanService.getKwitansi(`id=${id}`, 1, 1);
            const data = await response;            
            if(data.sukses === 'yes'){
                dispatch(setDetail(data.data))
            }else{
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}

